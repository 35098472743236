import settings from '~/settings'

export default async (path, isPositive) =>
  fetch(`${ settings.external.api }/feedback`, {
    method: 'post',
    body: JSON.stringify({ path, isPositive }),
    headers: {
      'Accept': 'application/json',
      'Content-Type': 'application/json',
    },
  })

