import React from 'react'
import { Link } from 'gatsby'
import { Trans, t } from '@lingui/macro'
import { withLocale } from 'components/common/Locale'
import Button from 'components/common/Button'
import routes from '~/routes'
import style from './notallowed.module.scss'

export default withLocale(({ i18n }) => (
  <div className={ style.main }>
    <Trans>
      Este contenido sólo esta disponible para usuarios registrados.
    </Trans>
    <div className={ style.actions }>
      <Link to={ routes.register() }>
        <Button className={ style.button } isPrimary>
          <Trans>¡Únete a lemon!</Trans>
        </Button>
      </Link>
      <Link to={ routes.login() }>
        <Button className={ style.button } isSecondaryDark>
          <Trans>Accede</Trans>
        </Button>
      </Link>
    </div>
  </div>
))
