import React from 'react'
import NavigationHelper from 'components/block/NavigationHelper'
import { InstitutionalBreadcrumb } from 'components/block/Breadcrumb'
import ArticleBody from './Body'
import style from './article.module.scss'

export default ({ data, location }) => {
  const { title, body } = data

  return (
    <div className={ style.main }>
      <NavigationHelper title={ title }>
        <InstitutionalBreadcrumb
          article={ data }
          location={ location }
        />
      </NavigationHelper>
      <div className={ style.container }>
        <ArticleBody
          body={ body.childMarkdownRemark.html }
        />
      </div>
    </div>
  )
}