import api from '~/api'
import { wait } from '~/utils/misc'
import settings from '~/settings'

export default async (entryId, locale, token) => {
  try {
    const body = api.authorizedGet(`${
      settings.external.api
    }/fullentry?entryId=${
      entryId
    }&locale=${
      locale
    }`, undefined, undefined, token)
    return body
  } catch (e) {
    return ''
  }
}
