import React from 'react'
import cx from 'classnames'
import { get, map } from 'lodash'
import { Trans } from '@lingui/macro'
import routes from '~/routes'
import { withLocale } from 'components/common/Locale'
import ContentItems from '../ContentItem/ContentItems'
import style from './related.module.scss'

export default withLocale(({ className, items = [] }) => (
  <div className={ cx(style.main, className) }>
    <h3 className={ style.title }>
      <Trans>También te puede interesar…</Trans>
    </h3>
    <ContentItems items={
      map(items, article => ({
        url: routes.article(article),
        title: article.title,
        image: get(article, 'heroImage.fluid.src'),
      }))
    } />
  </div>
))
