import React from 'react'
import { isEmpty } from 'lodash'
import { withAuth } from 'components/common/Auth'
import NavigationHelper from 'components/block/NavigationHelper'
import { ArticleBreadcrumb } from 'components/block/Breadcrumb'
import Feedback from 'components/block/Feedback'
import Related from 'components/block/Related'
import ArticleBody from './Body'
import style from './article.module.scss'

export { default as InstitutionalArticle } from './InstitutionalArticle'

const isAllowed = (user, visibility) =>
  visibility === 'public' ||
  visibility === 'private' && !isEmpty(user) ||
  visibility === 'professional' && user.type === 'professional'

const Article = ({ data, location, onFeedback, isFeedingBack, feedback, user = {} }) => {
  const { category, title, body, related, slug, contentful_id, node_locale } = data

  return (
    <div className={ style.main }>
      <NavigationHelper title={ title }>
        <ArticleBreadcrumb article={ data } location={ location } />
      </NavigationHelper>
      <div className={ style.container }>
        <ArticleBody
          user={ user }
          entryId={ contentful_id }
          locale={ node_locale }
          body={ body ? body.childMarkdownRemark.html : undefined }
        />
      </div>
      {
        onFeedback && isAllowed(user, category.visibilityGroup.slug) ?
          <Feedback
            className={ style.feedback }
            isDisabled={ isFeedingBack }
            feedback={ feedback }
            onPositive={ () => onFeedback(true) }
            onNegative={ () => onFeedback(false) }
          /> :
          null
      }
      {
        related ?
          <Related className={ style.related } items={ related } /> :
          null
      }
    </div>
  )
}

export default withAuth(Article)
