import React, { Component } from 'react'
import { get } from 'lodash'
import { Default as Layout } from 'components/layout/Layout'
import postFeedback from '~/service/feedback'
import Article from 'components/section/Article'

export default class ArticlePage extends Component {

  state = {
    feedback: undefined,
    isFeedingBack: false,
  }

  handleFeedback = async feedback => {
    this.setState({ isFeedingBack: true })
    try {
      await postFeedback(location.pathname, Boolean(feedback))
      this.setState({ feedback })
    } catch (e) {
      console.log(e)
    }

    this.setState({ isFeedingBack: false })
  }

  render() {
    const { pageContext = {}, location } = this.props
    const { isFeedingBack, feedback } = this.state

    return (
      <Layout location={ location }>
        <Article
          {...{
            isFeedingBack,
            feedback,
            location
          }}
          data={ get(pageContext, 'data.article') }
          onFeedback={ this.handleFeedback }
        />
      </Layout>
    )
  }
}
