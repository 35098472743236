import React, { useState, useEffect } from 'react'
import Loading from '~/components/common/Loading'
import { withAuth } from '~/components/common/Auth'
import NotAllowed from '~/components/block/NotAllowed'
import fetchBody from '~/service/fetchBody'
import { fromMarkdown, withHeadingIds } from '~/utils/html'
import style from './articlebody.module.scss'

const ArticleBody = ({ user, isLoggedIn, accessToken = '', body, entryId, locale }) => {
  const [fetchedBody, setFetchedBody] = useState(body)
  const [isLoading, setIsLoading] = useState(!body && isLoggedIn === undefined)

  const handleFetchBody = async () => {
    setIsLoading(true)
    try {
      const result = await fetchBody(entryId, locale, accessToken)
      const body = fromMarkdown(result, { shouldAddHeadingIds: true })
      setFetchedBody(body)
    } catch (e) {
      console.log(e)
    }
    setIsLoading(false)
  }

  useEffect(() => {
    if (!body && !fetchedBody && isLoggedIn !== undefined) {
      try {
        handleFetchBody()
      } catch (e) {
        console.log(e)
      }
    }
  }, [body, fetchedBody, isLoggedIn, user])

  return (
    <div className={ style.wrapper }>
    {
      isLoading ?
      <Loading isFullScreen /> :
        fetchedBody ?
          <div
            className={ style.main }
            dangerouslySetInnerHTML={{
              __html: withHeadingIds(fetchedBody),
            }}
          /> :
          <NotAllowed />
    }
    </div>
  )
}

export default withAuth(ArticleBody)